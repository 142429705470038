import * as React from 'react'
import { Component } from 'react';
import { Table, Row, Col } from "antd-min";
import { PaginationParams } from "gl-commonui";
import { lazyInject, TYPES, fmtMsg, DateHelper } from '@app/util/index';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { ISchoolLicenseService, LicenseBillingHistoryModel, LicenseBillingHistoryModelPropNames } from '@app/service/admin/license/index';

class HistoryTable extends Table<LicenseBillingHistoryModel> { }

export interface HistoryTableProps {
    loading?: boolean
    canEdit?: boolean
    dataSource?: LicenseBillingHistoryModel[]
    pagination?: PaginationParams
    handleTablePageChange?: (pagination: any) => void
    history?: any
}
interface HistoryTableStates {
    loading?: boolean;
    tableLayout?: any;
    editSchoolLicenseModalVisible?: boolean;
    currentSchoolLicense?: LicenseBillingHistoryModel;
}

export class LicenseHistoryTableComponent extends Component<HistoryTableProps, HistoryTableStates> {
    @lazyInject(TYPES.ISchoolLicenseService)
    schoolLicenseService: ISchoolLicenseService
    tableLayout: any = {
        rowKey: 'id',
        bordered: false,
        onChange: this.props.handleTablePageChange
    };
    constructor(props, context) {
        super(props, context);
        this.state = {
            tableLayout: this.tableLayout
        };
    }
    getColumns() {
        return [
            {
                title: fmtMsg({id: GSAdminLocale.SchoolModelClass}),
                dataIndex: LicenseBillingHistoryModelPropNames.schoolClass,
                width: '20%',
                className: "classcol"
            }, {
                title: fmtMsg({id: GSAdminLocale.SchoolModelCampus}),
                dataIndex: LicenseBillingHistoryModelPropNames.campus,
                width: '24%'
            }, {
                title: fmtMsg({id: GSAdminLocale.SchoolModelLicenseType}),
                dataIndex: LicenseBillingHistoryModelPropNames.licenseType,
                width: '10%'
            }, {
                title: fmtMsg({id: GSAdminLocale.SchoolModelDigitalLicense}),
                dataIndex: LicenseBillingHistoryModelPropNames.digitalLicenseCount,
                width: '12%'
            }, {
                title: fmtMsg({id: GSAdminLocale.SchoolModelTextbookLicense}),
                dataIndex: LicenseBillingHistoryModelPropNames.textbookLicenseCount,
                width: '12%'
            }, {
                title: fmtMsg({id: GSAdminLocale.SchoolModelStudentCount}),
                dataIndex: LicenseBillingHistoryModelPropNames.studentCount,
                width: '12%'
            }, {
                title: fmtMsg({id: SchoolLocale.SchoolLicenseHistoryDate}),
                dataIndex: LicenseBillingHistoryModelPropNames.updateDate,
                width: "10%",
                render: (text, record, index) => {
                    return DateHelper.formatDate2Local(text);
                }
            }
        ];
    }
    renderView() {
        this.tableLayout.dataSource = this.props.dataSource;
        this.tableLayout.columns = this.getColumns();
        this.tableLayout.pagination = this.props.pagination;
        return <HistoryTable {...this.tableLayout} loading={this.props.loading}/>;
    }
    render() {
        return <React.Fragment>
            <Row>
                <Col>{this.renderView()}</Col>
            </Row>
        </React.Fragment>;
    }
}