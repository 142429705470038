import {GLColumn, GLTable} from "@app/components/admin/gl-table";
import {ColumnLink, WijmoGrid} from "@app/components/grid";
import {PathConfig} from "@app/config/pathconfig";
import {GSAdminLocale, SchoolLocale} from "@app/locales/localeid";
import {ISchoolLicenseService} from "@app/service/admin/license";
import {
    AttachmentType,
    ISchoolService,
    SASModel,
    SchoolBillingModel,
    SchoolBillingModelPropNames,
    SchoolClassInvoiceAdjustmentModel,
    SchoolClassInvoiceAdjustmentModelPropNames,
    UploadingStateModel,
} from "@app/service/schools/index";
import {AdjustmentDateFormat, ContextHelper, DateHelper, fmtMsg, lazyInject, TYPES} from "@app/util";
import {Col, Icon, Input, Modal, Popconfirm, Progress, Radio, Row, Upload} from "antd-min";
import {FormHelper, GLForm, GLFormComponentProps, GLUtil, MessageHelper, NotificationType} from "gl-commonui";
import moment from "moment";
import * as React from "react";
import {Component} from "react";
import {GridRef} from "@app/components/grid/grid";
import "./billingtable.less";
import {RcFile} from "antd/lib/upload/interface";
import {
    Aborter,
    AnonymousCredential,
    BlobURL,
    BlockBlobURL,
    ContainerURL,
    Pipeline,
    ServiceURL,
    StorageURL,
    uploadBrowserDataToBlockBlob
} from "@azure/storage-blob";
import {TransferProgressEvent} from "@azure/ms-rest-js";

const { Grid, Column } = WijmoGrid;

const { Dragger } = Upload;

export interface BillingTableProps {
    history?: any;
    containerPath?: string;
    gridRef: (ref: GridRef) => any;
}
export interface BillingTableState {
    adjustmentModalVisible: boolean;
    uploadingModalVisible: boolean;
    attachmentType: AttachmentType;
    fileList: any[];
    uploadingStates: UploadingStateModel[];
    loading: boolean;
    records: SchoolClassInvoiceAdjustmentModel[];
    isLinkNamePopulated: boolean;
}

class AdjustementTable extends GLTable<SchoolClassInvoiceAdjustmentModel> {}
class CampusColumn extends GLColumn<SchoolClassInvoiceAdjustmentModel> {}
class ClassColumn extends GLColumn<SchoolClassInvoiceAdjustmentModel> {}
class CountColumn extends GLColumn<SchoolClassInvoiceAdjustmentModel> {}
class DateColumn extends GLColumn<SchoolClassInvoiceAdjustmentModel> {}
class AdjustedByColumn extends GLColumn<SchoolClassInvoiceAdjustmentModel> {}

@GLForm.create()
export class BillingTableComponent extends Component<BillingTableProps & GLFormComponentProps, BillingTableState> {
    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService;

    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;
    private gridRefInternal: GridRef;

    constructor(props: BillingTableProps) {
        super(props);
        this.state = {
            adjustmentModalVisible: false,
            uploadingModalVisible: false,
            attachmentType: AttachmentType.File,
            fileList: [],
            uploadingStates: [],
            loading: false,
            records: [],
            isLinkNamePopulated: false
        };
        this.gridRefInternal = null;
    }
    currentRecord: SchoolBillingModel = null;

    showAdjustments = (e: any, record: SchoolBillingModel) => {
        e.preventDefault();
        const { regionId, schoolId } = GLUtil.pathParse(this.props.containerPath);
        this.currentRecord = record;
        // fetch records.
        this.setState({ adjustmentModalVisible: true, loading: true });
        this.licenseService.getBillingAdjustmentDetail({ schoolId, regionId, regionInvoiceId: record.regionLicenseInvoiceHistoryId }).then((res) => {
            this.setState({ loading: false, records: res.data });
        });
    };

    adjustmentModal = () => {
        const { loading, records } = this.state;
        return (
            <Modal
                onCancel={() => this.setState({ adjustmentModalVisible: false })}
                footer={null}
                visible={this.state.adjustmentModalVisible}
                title={`${fmtMsg({ id: SchoolLocale.SchoolLicenseHistoryAdjustments })} (${
                    this.currentRecord
                        ? this.formatDate(this.currentRecord.cycleStartDate) + " ~ " + this.formatDate(this.currentRecord.cycleEndDate)
                        : ""
                })`}
                className="adjustby-modal"
                width="700px"
                destroyOnClose
            >
                <Grid itemsSource={records} pagination={false} loading={loading} stickyHeaders={false}>
                    <Column
                        header={fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryCampus })}
                        binding={SchoolClassInvoiceAdjustmentModelPropNames.campus}
                    />
                    <Column header={fmtMsg({ id: GSAdminLocale.SupportClass })} binding={SchoolClassInvoiceAdjustmentModelPropNames.schoolClass} />
                    <Column
                        header={fmtMsg({ id: SchoolLocale.ClassesColumnCount })}
                        binding={SchoolClassInvoiceAdjustmentModelPropNames.adjustment}
                    />
                    <Column
                        header={fmtMsg({ id: SchoolLocale.SchoolLicenseHistoryDate })}
                        binding={SchoolClassInvoiceAdjustmentModelPropNames.adjustmentDate}
                        render={(text) => {
                            if (!text) {
                                return "";
                            }
                            return this.formatDate(text);
                        }}
                        align='right'
                    />
                    <Column
                        header={fmtMsg({ id: SchoolLocale.SchoolLicenseHistoryAdjustedBy })}
                        binding={SchoolClassInvoiceAdjustmentModelPropNames.adjustmentByUser}
                        render={(text, record, index) => {
                            if (!text) {
                                return fmtMsg({ id: GSAdminLocale.ChangeLogTabSystem });
                            }
                            return text;
                        }}
                    />
                </Grid>
            </Modal>
        );
    };

    beforeUpload = (file: RcFile) => {
        const allowedFileTypes = [".pdf", ".doc", ".docx", ".txt", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "text/plain"];
        const extValid = allowedFileTypes.includes(file.type);
        if (!extValid) {
            MessageHelper.Message(
                NotificationType.Failed,
                fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadFileValidationType }, { allowedTypes: "pdf, doc, docx, txt" })
            );
            return false;
        }
        const isBelowLimit = file.size / 1024 / 1024 < 50;
        if (!isBelowLimit) {
            MessageHelper.Message(
                NotificationType.Failed,
                fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadFileValidationSize }, { maxSize: 50 })
            );
            return false;
        }
        this.setState({fileList: [file]});
        return false;
    };

    draggerProps = () => ({
        name: 'file',
        multiple: false,
        accept: ".pdf,.doc,.docx,.txt",
        beforeUpload: this.beforeUpload,
        fileList: this.state.fileList,
        disabled: this.state.fileList.length > 0
    });

    showUploadModal = (e: any, record: SchoolBillingModel) => {
        e.preventDefault();
        this.currentRecord = record;
        this.setState({ uploadingModalVisible: true });
    };

    closeUploadModal = () => {
        this.setState({ uploadingModalVisible: false, fileList: [], isLinkNamePopulated: false });
    }

    clearUploadFile = () => {
        this.setState({ fileList: [] });
    }

    onAttachmentTypeChange = e => {
        this.setState({
            attachmentType: e.target.value,
            fileList: [],
            isLinkNamePopulated: false
        });
    };

    generateBlockBlobUrl = (sasModel: SASModel, browserFile) => {
        const pipeline: Pipeline = StorageURL.newPipeline(new AnonymousCredential(), {
            retryOptions: { maxTries: 4 }, // Retry options
            telemetry: { value: "HighLevelSample V1.0.0" }, // Customized telemetry string
        });
        const serviceURL = new ServiceURL(sasModel.api, pipeline);
        const containerURL = ContainerURL.fromServiceURL(serviceURL, sasModel.container);
        const blobName = `${sasModel.blob}${browserFile.name}`;
        const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
        return BlockBlobURL.fromBlobURL(blobURL);
    };

    addUploadingState = (regionLicenseInvoiceHistoryId: string) => {
        const { uploadingStates } = this.state;
        uploadingStates.push({regionLicenseInvoiceHistoryId, progress: 0});
        this.setState({ uploadingStates });
    }

    updateUploadingState = (regionLicenseInvoiceHistoryId: string, progress: number) => {
        const { uploadingStates } = this.state;
        const index = uploadingStates.findIndex(x => x.regionLicenseInvoiceHistoryId === regionLicenseInvoiceHistoryId);
        uploadingStates[index].progress = progress;
        this.setState({ uploadingStates });
    }

    removeUploadingState = (regionLicenseInvoiceHistoryId: string) =>
        this.setState({ uploadingStates: this.state.uploadingStates.filter(x => x.regionLicenseInvoiceHistoryId !== regionLicenseInvoiceHistoryId) });

    uploadFile = () => {
        if (this.state.fileList.length === 0) {
            MessageHelper.Message(
                NotificationType.Failed,
                fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadFileValidationMissing })
            );
            return;
        }
        const schoolId = GLUtil.pathParse(PathConfig.Schools).schoolId;
        const regionLicenseInvoiceHistoryId = this.currentRecord.regionLicenseInvoiceHistoryId;
        const file = this.state.fileList[0];
        const fileName = file.name;
        this.schoolService.getSASForBillingAttachment({ schoolId }).then((sasModel) => {
            this.closeUploadModal();
            this.addUploadingState(regionLicenseInvoiceHistoryId);
            uploadBrowserDataToBlockBlob(Aborter.none, file, this.generateBlockBlobUrl(sasModel, file), {
                blockSize: 4 * 1024 * 1024,
                parallelism: 20,
                blobHTTPHeaders: { blobContentType: file.type === "application/pdf" ? "application/pdf" : undefined },
                progress: (ev: TransferProgressEvent) => {
                    const progress = Math.round((ev.loadedBytes / file.size) * 100);
                    this.updateUploadingState(regionLicenseInvoiceHistoryId, progress);
                },
            })
                .then(() => {
                    this.createAttachment(fileName, sasModel.blob + fileName, sasModel.sourceCountryCode);
                })
                .catch(() => {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadFileFailed }));
                    this.removeUploadingState(regionLicenseInvoiceHistoryId);
                });
        });
    }

    createAttachment = (name: string, filePath: string, sourceCountryCode:string, callback?) => {
        this.schoolService.createBillingAttachment({
            schoolId: GLUtil.pathParse(PathConfig.Schools).schoolId,
            regionLicenseInvoiceHistoryId: this.currentRecord.regionLicenseInvoiceHistoryId,
            attachmentType: this.state.attachmentType,
            name,
            filePath,
            sourceCountryCode
        }).then(() => {
            MessageHelper.Message(NotificationType.Success, fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentSuccess }));
            this.gridRefInternal.reload();
            if (this.state.attachmentType === AttachmentType.File) {
                this.removeUploadingState(this.currentRecord.regionLicenseInvoiceHistoryId);
            }
            if (callback) {
                callback();
            }
        })
    }

    onUploadingSubmit = () => {
        if (this.state.attachmentType === AttachmentType.File) {
            this.uploadFile();
        } else if (this.state.attachmentType === AttachmentType.Link) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.createAttachment(values.name, values.link, values.sourceCountryCode, this.closeUploadModal);
                }
            });
        }
    }

    onLinkNameChange = (e: any) => {
        const { value } = e.target;
        if (value && value !== "") {
            this.setState({ isLinkNamePopulated: true });
        } else {
            this.setState({ isLinkNamePopulated: false });
        }
    }

    onLinkInputChange = (e: any) => {
        if (this.state.isLinkNamePopulated) {
            return;
        }
        const { value: url } = e.target;
        try {
            const pathname = new URL(url).pathname;
            const index = pathname.lastIndexOf('/');
            const fileNameWithExtension = pathname.substring(index + 1);
            const fileNameWithoutExtension = fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));
            const fileNameDecoded = decodeURI(fileNameWithoutExtension);
            this.props.form.setFieldsValue({ name: fileNameDecoded });
        } catch (e) {
            // do nothing
        }
    }

    uploadingModal = () => {
        const { form } = this.props;
        const { renderFormItem } = FormHelper;
        return (
            <Modal
                onCancel={this.closeUploadModal}
                onOk={this.onUploadingSubmit}
                okText={fmtMsg({ id: GSAdminLocale.ButtonSave })}
                visible={this.state.uploadingModalVisible}
                title={`${fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadInvoiceTitle })}${
                    this.currentRecord
                        ? " (" + this.formatDate(this.currentRecord.cycleStartDate) + " ~ " + this.formatDate(this.currentRecord.cycleEndDate) + ")"
                        : ""
                }`}
                className="uploading-modal"
                width="800px"
                destroyOnClose
            >
                <div className="uploading-modal-body">
                    <Radio.Group onChange={this.onAttachmentTypeChange} value={this.state.attachmentType}>
                        <Radio value={AttachmentType.File}>File</Radio>
                        <Radio value={AttachmentType.Link}>Link</Radio>
                    </Radio.Group>
                    {this.state.attachmentType === AttachmentType.File
                        ? <>
                            {this.state.fileList.length > 0
                                ?
                                <Row type="flex" gutter={8}>
                                    <Col>{this.state.fileList[0].name}</Col>
                                    <Col>
                                        <Icon type="close-circle" title="delete" onClick={() => this.clearUploadFile()} />
                                    </Col>
                                </Row>
                                :
                                <div>
                                    <Dragger {...this.draggerProps()}>
                                        <p className="ant-upload-text">{fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadInvoiceDragDrop })}</p>
                                    </Dragger>
                                </div>
                            }
                            <div className="uploading-modal-body__note">{fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadInvoiceFileNote }, { allowedTypes: "pdf, doc, docx, txt" })}</div>
                        </>
                        : <>
                            <GLForm form={form} labelCol={{ sm: 5, md: 4 }} wrapperCol={{ sm: 19, md: 20 }}>
                                {
                                    renderFormItem(
                                        { ...form, options: { formItemProps: { label: SchoolLocale.LicenseHistoryAttachmentUploadInvoiceLinkName } } },
                                        SchoolLocale.LicenseHistoryAttachmentUploadInvoiceLinkName,
                                        "name",
                                        <Input onChange={this.onLinkNameChange} />,
                                        null,
                                        true,
                                        []
                                    )
                                }
                                {
                                    renderFormItem(
                                        { ...form, options: { formItemProps: { label: SchoolLocale.LicenseHistoryAttachmentUploadInvoiceLinkLink } } },
                                        SchoolLocale.LicenseHistoryAttachmentUploadInvoiceLinkLink,
                                        "link",
                                        <Input onChange={this.onLinkInputChange} />,
                                        null,
                                        true,
                                        []
                                    )
                                }
                            </GLForm>
                            <div className="uploading-modal-body__note">{fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentUploadInvoiceLinkNote })}</div>
                        </>
                    }
                </div>
            </Modal>
        );
    };

    deleteAttachment = (record: SchoolBillingModel) => {
        this.schoolService.deleteBillingAttachment({
            schoolId: GLUtil.pathParse(PathConfig.Schools).schoolId,
            regionLicenseInvoiceHistoryId: record.regionLicenseInvoiceHistoryId,
        }).then(() => {
            this.gridRefInternal.reload();
            MessageHelper.Message(NotificationType.Success, fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentDeleteSuccess }));
        });
    };

    formatDate = (date: string) => {
        return DateHelper.toLocalDate(moment(date, AdjustmentDateFormat));
    };

    serviceFunction(paginationParams: object) {
        const params = {
            pagingParams: paginationParams,
            routeParams: {
                schoolId: GLUtil.pathParse(PathConfig.Schools).schoolId,
                regionId: GLUtil.pathParse(PathConfig.Schools).regionId,
            },
        };
        return this.schoolService.getSchoolBillingHistory(params);
    }

    hasManagePermission = () => {
        const userInfo = ContextHelper.getUserLoginInfo();
        const roles = userInfo && userInfo.profile && userInfo.profile.roleInfos ? userInfo.profile.roleInfos : null;
        const allowedRoles = ["RegionAdmin", "TrainingAdmin", "SystemAdmin", "AccountManager"];
        return roles.some(r => allowedRoles.includes(r.name));
    }

    handleRemoveBillingLink = (record: SchoolBillingModel) => {
        Modal.confirm({
            title: fmtMsg({ id: SchoolLocale.LicenseHistoryAttachmentDeleteConfirm }),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () =>  {
                this.deleteAttachment(record);
            }
        });
    }

    billingCell = (record: SchoolBillingModel) => {
        if (record.fileName) {
            return (
                <div className={"billing-cell"}>
                    <a href={record.attachmentUrl} target="_blank">{record.fileName}</a>
                    { this.hasManagePermission() ? <Icon type={"close"} onClick={() => this.handleRemoveBillingLink(record)} className={"billing-cell__close-button"}/> : null }
                </div>
            );
        }
        if (!this.hasManagePermission()) {
            return null;
        }
        const uploadingState = this.state.uploadingStates.find((state) => state.regionLicenseInvoiceHistoryId === record.regionLicenseInvoiceHistoryId);
        if (uploadingState) {
            return <Progress percent={uploadingState.progress} />
        }
        return <Icon type={"upload"} onClick={(e) => this.showUploadModal(e, record)} />;
    }

    setGridRef = (ref: GridRef) => {
        this.props.gridRef(ref);
        this.gridRefInternal = ref;
    }

    formatData = (data) => {
        if (!data) {
            return [];
        }
        return data.map((record) => ({
            ...record,
            billingPeriod: this.formatDate(record.cycleStartDate) + " ~ " + this.formatDate(record.cycleEndDate)
        }))
    }

    render() {
        const { regionId, schoolId } = GLUtil.pathParse(this.props.containerPath);
        return (
            <>
                <Grid ref={this.setGridRef} serviceFunction={this.serviceFunction.bind(this)} serviceFormatData={data => this.formatData(data.data)} allowSorting={false}>
                    <Column
                        header={fmtMsg({ id: GSAdminLocale.SchoolBillingModelPeriod })}
                        binding={SchoolBillingModelPropNames.billingPeriod}
                        render={(text, record) => {
                            return (
                                <ColumnLink
                                    history={this.props.history}
                                    url={GLUtil.pathStringify(PathConfig.SchoolLicenseHistory, {
                                        regionId,
                                        schoolId,
                                        historyId: record.regionLicenseInvoiceHistoryId,
                                    })}
                                >
                                    <span>{text}</span>
                                </ColumnLink>
                            );
                        }}
                    />
                    <Column
                        header={fmtMsg({ id: GSAdminLocale.SchoolBillingModelCloseDate })}
                        binding={SchoolBillingModelPropNames.closeDate}
                        render={(text, record) => {
                            return DateHelper.formatDateTime2Local(record[SchoolBillingModelPropNames.closeDate], false, "MM/DD/YYYY h:mm:ss a");
                        }}
                    />
                    <Column
                        binding={SchoolBillingModelPropNames.fileName}
                        render={(text, record) => this.billingCell(record)}
                        maxWidth={600}
                    />
                    <Column
                        header={fmtMsg({ id: SchoolLocale.SchoolLicenseHistoryAdjustments })}
                        binding={SchoolBillingModelPropNames.adjustments}
                        render={(text, record) => {
                            return <a onClick={(e) => this.showAdjustments(e, record)}>{text}</a>;
                        }}
                    />
                </Grid>
                {this.adjustmentModal()}
                {this.uploadingModal()}
            </>
        );
    }
}
